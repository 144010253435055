import gql from "graphql-tag";
import {
  Agent,
  AgentCreateInput,
  AgentEdgesResponse,
  AgentResponse,
  AgentSearchInput,
  AgentUpdateInput,
} from "../gen/types";
import { ResponseGql } from "../types/MDOTypes";
import { BaseService } from "./BaseService";

export class AgentService extends BaseService {

  constructor() {
    super();
  }

  get(id: string): ResponseGql<Agent> {
    const query = gql`query agent($id: ID!) {
      agent(id: $id) {
        id
        name
        enable
      }
    }`;

    return this.apolloClient.query({ query: query, variables: { id: id } }).then(result => {
      return result.data.agent;
    });
  }

  getAll(agentSearch: AgentSearchInput): ResponseGql<AgentEdgesResponse> {
    const query = gql`query agents($agentSearch: AgentSearchInput!) {
      agents(params: $agentSearch) {
        edges {
          id
          name
          enable
        }
        pagination {
          total
          page
        }
      }
    }`;

    return this.apolloClient.query({ query: query, variables: { agentSearch: agentSearch } }).then(result => {
      return result.data.agents;
    });
  }
  getToOptions(): ResponseGql<AgentEdgesResponse> {
    const search: AgentSearchInput = {
      and: {
        enable: true
      },
      perPage: -1
    }
    const query = gql`query agents($agentSearch: AgentSearchInput!) {
      agents(params: $agentSearch) {
        edges {
          id
          name
        }
      }
    }`;

    return this.apolloClient.query({ query: query, variables: { agentSearch: search } }).then(result => {
      return result.data.agents;
    });
  }

  getAllCombo(onlyEnable = true): ResponseGql<AgentEdgesResponse> {
    const search: AgentSearchInput = {};
    if (onlyEnable) {
      search.and = { enable: true }
    }
    const query = gql`query agents($agentSearch: AgentSearchInput!) {
      agents(params: $agentSearch) {
        edges {
          id
          name
        }
      }
    }`;

    return this.apolloClient.query({ query: query, variables: { agentSearch: search } }).then(result => {
      return result.data.agents;
    });
  }

  create(agent: AgentCreateInput): ResponseGql<AgentResponse> {
    const mutation = gql`mutation createAgent($agentCreate: AgentCreateInput!) {
      createAgent(input: $agentCreate) {
        data {
          id
          name
          enable
        }
        message
      }
    }`;

    return this.apolloClient.mutate({ mutation: mutation, variables: { agentCreate: agent }})
    .then((result) => result.data.createAgent);
  }

  update(id: string, agent: AgentUpdateInput): ResponseGql<AgentResponse> {
    const mutation = gql`mutation updateAgent($id: ID!, $agentUpdate: AgentUpdateInput!) {
      updateAgent(id: $id, input: $agentUpdate) {
        data {
          id
          name
          enable
        }
        message
      }
    }`;

    return this.apolloClient.mutate({ mutation: mutation, variables: { id: id, agentUpdate: agent}})
    .then((result) => result.data.updateAgent);
  }

  remove(id: string): ResponseGql<AgentResponse> {
    const mutation = gql`mutation removeAgent($id: ID!) {
      removeAgent(id: $id) {
        data {
          id
        }
        message
      }
    }`;

    return this.apolloClient.mutate({ mutation: mutation, variables: { id: id }})
    .then((result) => result.data.removeAgent);
  }

}
