import gql from "graphql-tag";
import { Book, BookEdgesResponse, BookSearchInput, CreateBookResponse } from "../gen/types";
import { ResponseGql } from "@/codes/types/MDOTypes";
import { BaseService } from "./BaseService";

export class BookService extends BaseService {

  constructor() {
    super();
  }

  create(quantity: number): ResponseGql<CreateBookResponse> {
    const mutationCreate = gql`mutation createBook($quantity: Int!) {
      createBook(quantity: $quantity) {
        message
        quantity
        lote
        sequenceBegin
        sequenceEnd
      }
    }`;

    return this.apolloClient.mutate({ mutation: mutationCreate, variables: { quantity: quantity }}).then(result => {
      return result.data.createBook;
    });
  }

  getAll(bookSearch: BookSearchInput): ResponseGql<BookEdgesResponse> {
    const queryAll = gql`query books($bookSearch: BookSearchInput) {
      books(params: $bookSearch) {
        edges {
          id
          sequential
          status
          qtScannedSheets
          qtExamDone
          qtAbandoned
        }
        pagination {
          total
          page
        }
        linkDownloadCSV
        linkDownloadPDF
        linkDownloadPDFLayout
      }
    }`;

    return this.apolloClient.query({ query: queryAll, variables: { bookSearch: bookSearch }}).then(result => {
      return result.data.books;
    });
  }

  get(sequential: number): ResponseGql<Book> {
    const query = gql`query book($sequential: Int!) {
      book(sequential: $sequential) {
        id
        sequential
        status
        qtScannedSheets
        qtExamDone
        qtAbandoned
        lote
        campaign {
          id
          name
        }
        account {
          id
          name
        }
        agent {
          id
          name
        }
      }
    }`;

    return this.apolloClient.query({ query: query, variables: { sequential: sequential }}).then(result => {
      return result.data.book;
    });
  }

  // filter(page = 1, perPage = 1, lote: string): ResponseGql<BookEdgesResponse> {
  //   const query = gql`query books($paginationBooks: BookSearchInput) {
  //     books(params: $paginationBooks) {
  //       edges {
  //         id
  //         sequential
  //         status
  //       }
  //       pagination {
  //         total
  //         page
  //       }
  //     }
  //   }`;

  //   return this.apolloClient.query({ query: query, variables: {
  //       paginationBooks: { perPage: perPage, page: page, and: { lote: lote } }
  //     }}).then(result => {
  //       return result.data.books;
  //     });
  // }

}