import gql from "graphql-tag";
import { Campaign, CampaignCreateInput, CampaignEdgesResponse, CampaignResponse, CampaignSearchInput, CampaignUpdateInput } from "../gen/types";
import { ResponseGql } from "../types/MDOTypes";
import { BaseService } from "./BaseService";

export class CampaignService extends BaseService {

  constructor() {
    super();
  }

  get(id: string): ResponseGql<Campaign> {
    const query = gql`query campaign($id: ID!) {
      campaign(id: $id) {
        id
        name
        description
        enable
      }
    }`;

    return this.apolloClient.query({ query: query, variables: { id: id } }).then(result => {
      return result.data.campaign;
    });
  }

  getAll(campaignSearch: CampaignSearchInput): ResponseGql<CampaignEdgesResponse> {
    const query = gql`query campaigns($campaignSearch: CampaignSearchInput) {
      campaigns(params: $campaignSearch) {
        edges {
          id
          name
          description
          enable
          whatsapp
        }
        pagination {
          total
          page
        }
      }
    }`;

    return this.apolloClient.query({ query: query, variables: { campaignSearch: campaignSearch } }).then(result => {
      return result.data.campaigns;
    });
  }

  getToOptions(): ResponseGql<CampaignEdgesResponse> {
    const search: CampaignSearchInput = {
      and: {
        enable: true
      },
      perPage: -1
    }
    const query = gql`query campaigns($campaignSearch: CampaignSearchInput) {
      campaigns(params: $campaignSearch) {
        edges {
          id
          name
          description
          enable
        }
        pagination {
          total
          page
        }
      }
    }`;

    return this.apolloClient.query({ query: query, variables: { campaignSearch: search } }).then(result => {
      return result.data.campaigns;
    });
  }

  getAllCombo(onlyEnable = true): ResponseGql<CampaignEdgesResponse> {
    const campaignSearch: CampaignSearchInput = {};
    if (onlyEnable) {
      campaignSearch.and = { enable: true }
    }
    const query = gql`query campaigns($campaignSearch: CampaignSearchInput) {
      campaigns(params: $campaignSearch) {
        edges {
          id
          name
        }
      }
    }`;

    return this.apolloClient.query({ query: query, variables: { campaignSearch: campaignSearch } }).then(result => {
      return result.data.campaigns;
    });
  }

  create(campaign: CampaignCreateInput): ResponseGql<CampaignResponse> {
    const mutation = gql`mutation createCampaign($campaign: CampaignCreateInput!) {
      createCampaign(input: $campaign) {
        data {
          id
          name
          description
          enable
          whatsapp
        }
        message
      }
    }`;

    return this.apolloClient.mutate({ mutation: mutation, variables: { campaign: campaign }})
    .then((result) => result.data.createCampaign);
  }

  update(id: string, campaign: CampaignUpdateInput): ResponseGql<CampaignResponse> {
    const mutation = gql`mutation updateCampaign($id: ID!, $campaign: CampaignUpdateInput!) {
      updateCampaign(id: $id, input: $campaign) {
        data {
          id
          name
          description
          enable
        }
        message
      }
    }`;

    return this.apolloClient.mutate({ mutation: mutation, variables: { id: id, campaign: campaign }})
    .then((result) => result.data.updateCampaign);
  }

  remove(id: string): ResponseGql<CampaignResponse> {
    const mutation = gql`mutation removeCampaign($id: ID!) {
      removeCampaign(id: $id) {
        data {
          id
        }
        message
      }
    }`;

    return this.apolloClient.mutate({ mutation: mutation, variables: { id: id }})
    .then((result) => result.data.removeCampaign);
  }

}
